/**
 *
 * Session storage key name: oho_web_app_session
 *
 * Data structure
 * {
 *   "biz_1": {
 *     "chat_rooms": {
 *       "room_1": {
 *         "message_input": "Hello"
 *       },
 *     },
 *     "channels": [
 *       { _id: "channel_1", is_selected: true }
 *     ]
 *   }
 * }
 *
 */

const SESSION_KEY = "oho_web_app_session"

const getSessionData = () => {
  return JSON.parse(sessionStorage.getItem(SESSION_KEY)) || {}
}

const setSessionData = (data) => {
  try {
    sessionStorage.setItem(SESSION_KEY, JSON.stringify(data))
  } catch (error) {
    throw error
  }
}

const clearBizState = (biz_id, data) => {
  if (data) {
    for (const key in data) {
      if (biz_id !== key) {
        delete data[key]
      }
    }
  }
  return data
}

const clearRoomState = (biz_id, room_id, data) => {
  if (data[biz_id]?.chat_rooms) {
    for (const key in data[biz_id].chat_rooms) {
      if (room_id !== key) {
        delete data[biz_id].chat_rooms[key]
      }
    }
  }
  return data
}

export default ({ store }, inject) => {
  const sessionStorageHelper = {
    saveSelectedChannels(biz_id, selected_channels) {
      const session_data = getSessionData()
      const all_channels = store?.state?.channels || []

      if (!session_data[biz_id]) {
        session_data[biz_id] = {
          chat_rooms: {},
          channels: [],
        }
      }

      session_data[biz_id].channels = all_channels.map((item) => ({
        _id: item._id,
        is_selected: selected_channels.includes(item._id),
      }))

      try {
        setSessionData(session_data)
      } catch (error) {
        if (error?.name === "QuotaExceededError") {
          setSessionData(clearBizState(biz_id, session_data))
        }
      }
    },
    loadSelectedChannels(biz_id) {
      const session_data = getSessionData()
      const all_channels = store?.state?.channels || []
      const channels = session_data[biz_id]?.channels || []
      const result = all_channels
        .map((item) => {
          return {
            _id: item._id,
            is_selected:
              channels.find((sub_item) => sub_item._id === item._id)
                ?.is_selected ?? true,
          }
        })
        .filter((item) => item.is_selected)
        .map((item) => item._id)

      if (result.length > 0) {
        return result
      } else {
        const backup = all_channels
          .map((item) => {
            return {
              _id: item._id,
              is_selected: true,
            }
          })
          .map((item) => item._id)
        sessionStorageHelper.saveSelectedChannels(biz_id, backup)
        return backup
      }
    },
    saveRoomState(biz_id, room_id, state) {
      const session_data = getSessionData()
      if (!session_data[biz_id]) {
        session_data[biz_id] = {
          chat_rooms: {},
          channels: [],
        }
      }
      if (!session_data[biz_id]?.chat_rooms[room_id]) {
        session_data[biz_id].chat_rooms[room_id] = {
          message_input: "",
        }
      }
      session_data[biz_id].chat_rooms[room_id] = {
        ...session_data[biz_id].chat_rooms[room_id],
        ...state,
      }

      try {
        setSessionData(session_data)
      } catch (error) {
        if (error?.name === "QuotaExceededError") {
          setSessionData(clearRoomState(biz_id, room_id, session_data))
        }
      }
    },
    saveRoomMessageInput(biz_id, room_id, message_input) {
      const room_state = {
        message_input: message_input,
      }
      sessionStorageHelper.saveRoomState(biz_id, room_id, room_state)
    },
    loadRoomState(biz_id, room_id) {
      const session_data = getSessionData()
      return (
        session_data[biz_id]?.chat_rooms[room_id] || {
          message_input: "",
        }
      )
    },
  }

  inject("sessionStorageHelper", sessionStorageHelper)
}
